import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../utils/FontAwesome";
import LogoSyndicat from "../images/logosyndicat.png";
import { HorrairesContext } from "../context/HorrairesContext";
FontAwesome();
const Footer = () => {
  const [data] = useContext(HorrairesContext);
  const [horraires] = useState(data.allDatoCmsHorraire.edges);
  return (
    <FooterWrapper>
      <div className="adress">
        <Link to="/">
          <h4>Magali Sophrologue</h4>
        </Link>
        <ul>
          <li>135 Chemin du vieux colombier 83130 La Garde</li>
          <li>06 15 70 78 79</li>
          <li>contact@magalisophrologue.fr</li>
          <li>www.magalisophrologue.fr</li>
          <li>
            <Link to="/mentions">
              <p>Mention legales</p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="desc">
        <h4>Horraires d'ouverture</h4>
        <ul>
          {horraires.map(({ node: horraire }) => {
            return (
              <li key={horraire.id}>
                {horraire.jour} {horraire.horraire}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="socialWrapper">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/magalisophrologue/"
              target="_blank"
              rel="noreferrer">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </li>
        </ul>
        <img
          src={LogoSyndicat}
          alt="Logo de la chambre syndicale des sophrologues"
        />
      </div>
      <div className="footerMvdev">
        <p>
          Magali Sophrologue 2022 © Création{" "}
          <a href="https://www.mvdev.fr">MVDev</a>
        </p>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  width: 100%;
  min-height: 250px;
  height: auto;
  padding: 2rem 2rem;
  background: var(--green);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5% 0 0 0;
  color: var(--background-color);
  .adress {
    width: 33%;
    justify-content: center;
    padding: 1rem;

    h6 {
      padding-left: 2.3rem;
    }
    a {
      color: var(--background-color);
      transition: 0.7s ease-in-out;
      cursor: pointer;
    }
    a:hover {
      color: #a2a59a;
    }
    ul li {
      font-family: "Lato", serif;
      margin-bottom: -0.1rem;
    }
    @media screen and (max-width: 875px) {
      width: 100%;
      text-align: end;
    }
  }
  .desc {
    width: 33%;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    text-align: center;

    a {
      color: var(--background-color);
      transition: 0.7s ease-in-out;
      cursor: pointer;
    }
    a:hover {
      color: #a2a59a;
    }
    ul li {
      font-family: "Lato", serif;
      margin-bottom: -0.1rem;
    }
    @media screen and (max-width: 875px) {
      width: 100%;
    }
  }
  .socialWrapper {
    width: 33%;
    justify-content: center;
    padding: 1rem;

    ul {
      display: flex;
      justify-content: center;
    }

    a {
      width: 2.3rem;
      font-size: 2rem;
      padding: 0 0.5rem;
      transition: 0.7s ease-in-out;
      cursor: pointer;
      color: var(--background-color);
    }
    a:hover {
      color: #a2a59a;
    }
    img {
      width: 10rem;
      text-align: center;
      filter: brightness(0.5);
    }
    @media screen and (max-width: 875px) {
      width: 90%;
    }
  }

  .footerMvdev {
    width: 100%;
    margin: 4rem 0 0 0;
    font-family: "Roboto Slab", serif;
    margin: 0 auto;
    p {
      text-align: center;
      margin: 3rem 0 0 0;
    }
    a {
      color: var(--background-color);
      transition: 0.7s ease-in-out;
      cursor: pointer;
    }
    a:hover {
      color: #a2a59a;
    }
  }
`;

export default Footer;
