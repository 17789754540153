import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const HorrairesContext = createContext();

export const HorrairesProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsHorraire(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            jour
            horraire
          }
        }
      }
    }
  `);

  return (
    <HorrairesContext.Provider value={[data]}>
      {children}
    </HorrairesContext.Provider>
  );
};
