import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import LogoHeader from "../images/LogoHeader.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FontAwesome from "../utils/FontAwesome";

FontAwesome();

const NavBar = ({ offset }) => {
  const [showLinks, setShowLinks] = useState(false);
  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };
  return (
    <NavBarWrapper>
      <nav className={`${showLinks ? "ShowLinks" : "hidden "}`}>
        <div className="NavBar_Logo">
          <img src={LogoHeader} alt="Magali Giacobazzi Sophrologue" />
        </div>
        <ul className="NavBar_Links">
          <li className="NavBar_Item">
            <Link to="/" className="NavBar_Link">
              Accueil
            </Link>
          </li>
          <li className="NavBar_Item">
            <Link to="/Quijesuis" className="NavBar_Link">
              Qui je suis ?
            </Link>
          </li>
          <li className="NavBar_Item">
            <Link to="/Tarifs" className="NavBar_Link">
              Tarifs
            </Link>
          </li>
          <li className="NavBar_Item">
            <Link to="/Contact" className="NavBar_Link">
              Contact
            </Link>
          </li>
          <li className="NavBar_Item social">
            <a
              href="https://www.instagram.com/magalisophrologue/"
              target="_blank"
              rel="noreferrer"
              className="NavBar_Link">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </li>
        </ul>
        <button className="NavBar_Buger" onClick={handleShowLinks}>
          <span className="Burger_Bar"></span>
        </button>
      </nav>
    </NavBarWrapper>
  );
};

const NavBarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  min-height: 75px;
  color: var(--green);
  .NavBar_Logo {
    position: absolute;
    width: 348px;
    top: 0;

    img {
      margin-top: -7rem;
      width: 348px;
    }
  }
  .NavBar_Links {
    position: absolute;
    top: 2rem;
    left: 60%;
    display: flex;
    font-size: 1rem;
  }

  .ShowLinks {
  }

  .NavBar_Item {
    margin-left: 0.8rem;
    .social {
    }
  }

  .NavBar_Link {
    padding: 0 0.3rem;
    color: inherit;
    transition: ease-in-out 0.4s;
  }

  .NavBar_Link:hover {
    color: var(--gray);
  }

  .socialWrapper {
    position: absolute;
    top: 2rem;
    left: 90%;
    display: flex;

    a {
      padding: 0 0.3rem;
      color: inherit;
      transition: ease-in-out 0.4s;
    }
    a:hover {
      color: var(--gray);
    }
  }

  .NavBar_Buger {
    width: 45px;
    height: 45px;
    background: transparent;
    border: none;
    color: inherit;
    position: absolute;
    top: 0;
    right: 10;
  }
  @media screen and (max-width: 1215px) {
    .NavBar_Links {
      left: 50%;
    }
  }
  @media screen and (max-width: 1000px) {
    .NavBar_Links {
      left: 40%;
    }
  }

  @media screen and (max-width: 876px) {
    .NavBar_Links {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: -300vw;
      height: 110vh;
      padding: 2rem;
      background: var(--background-color);
      z-index: 100;
      visibility: hidden;
      transition: all ease-in-out 0.5s;
    }

    .ShowLinks .NavBar_Links {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      top: 0;
      left: 0;
      height: 100vh;
      padding: 2rem;
      background: var(--background-color);
      z-index: 100;
      visibility: visible;
      transition: all ease-in-out 0.5s;
    }
    .NavBar_Link {
      display: block;
      padding: 1.5rem;
      font-size: 5vw;
    }
    .NavBar_Buger {
      z-index: 101;
      top: 1rem;
      left: 80%;
    }
    .NavBar_Buger:hover {
      cursor: pointer;
    }

    .Burger_Bar,
    .Burger_Bar::before,
    .Burger_Bar::after {
      display: block;
      width: 33px;
      height: 4px;
      background: var(--gray);
      z-index: 101;
      transition: all 0.4s ease-in-out;
    }
    .Burger_Bar::before,
    .Burger_Bar::after {
      content: "";
      display: block;
      border-radius: 3px;

      transition: 0.5s;
    }
    .Burger_Bar::before {
      transform: translateY(12px);
    }
    .Burger_Bar::after {
      transform: translateY(-16px);
    }
    .ShowLinks .Burger_Bar {
      background: transparent;
      width: 0;
    }
    .ShowLinks .Burger_Bar::before {
      transform: rotate(45deg) translate(2px);
    }
    .ShowLinks .Burger_Bar::after {
      transform: rotate(-45deg) translate(3px);
    }
    .NavBar_Logo {
      width: 280px;
      top: 1rem;
    }
  }
`;

export default NavBar;
