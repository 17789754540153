import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsQuiJeSui {
        nodes {
          id
          titre
          description
          portrait {
            url
          }
        }
      }
    }
  `);
  return (
    <AboutContext.Provider value={[data]}>{children}</AboutContext.Provider>
  );
};
