import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";

function Header({ offset }) {
  return (
    <HeaderWrapper>
      <NavBar offset={offset} />
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  position: relative;
  height: 80px;
`;

export default Header;
