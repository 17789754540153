import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./Global.css";
import { HorrairesProvider } from "../context/HorrairesContext";
import { AboutProvider } from "../context/AboutContext";

const Layout = ({ children, offset }) => {
  return (
    <>
      <Header offset={offset} />
      <AboutProvider>
        <main style={{ minHeight: "20vh", height: "auto" }}>{children}</main>
      </AboutProvider>
      <HorrairesProvider>
        <Footer />
      </HorrairesProvider>
    </>
  );
};

export default Layout;
