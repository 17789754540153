import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faGithub,
  faReact,
  faHtml5,
  faCss3,
  faJs,
  faNodeJs,
  faWordpress,
} from "@fortawesome/free-brands-svg-icons"

export default function FontAwesome() {
  library.add(
    fab,
    faTwitterSquare,
    faFacebook,
    faLinkedin,
    faGithub,
    faReact,
    faNodeJs,
    faHtml5,
    faCss3,
    faJs,
    faWordpress
  )
}
